import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { getTermsOfUse, setTermsOfUseAccepted } from "../../helper/realtimeDatabase/termsOfUse";
import { ALL_LICENSE } from "../../constants";
import { useAuthContext } from "../../auth/useAuthContext";
import { useSnackbar } from "notistack";

export default function TermsOfUseModal({show, onClose}){
  const {enqueueSnackbar} = useSnackbar();
  const {logout} = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [htmlData, setHtmlData] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true)
    getTermsOfUse(ALL_LICENSE.restaurant).then(value => {
      setHtmlData(value.legalPrompt)
    }).finally(() => {
      setLoading(false);
    })
  }

  const handleCancelClick = () => {
    try {
      logout();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Greška!", {variant: "error"});
    }
  }

  const handleAcceptClick = () => {
    setSubmitting(true);
    setTermsOfUseAccepted(ALL_LICENSE.restaurant).then(_ => {
      onClose();
    }).catch(reason => {
      console.error("handleAcceptClick", reason);
      enqueueSnackbar("Greška! Pokušajte ponovo.", {variant: "error"});
    }).finally(() => {
      setSubmitting(false);
    })
  }

  return(
    <Dialog open={show}>
      <DialogTitle>
        Opšti uslovi korišćenja
      </DialogTitle>
      <DialogContent sx={{
        mt: 3
      }}>
        {loading ? <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>:
        <div dangerouslySetInnerHTML={{ __html: htmlData ||
            '<div>Greška prilikom očitavanja podataka. Osvežite stranicu!</div>' }}/>}
      </DialogContent>
      <DialogActions>
        <Button onClick={submitting ? null : handleCancelClick}>
          Odustani
        </Button>
        <LoadingButton
          onClick={handleAcceptClick}
          loading={submitting} variant="contained" color="primary">
          Prihvati
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
