import { getSessionOperator } from "../../session";
import { collection, doc, getDocs, query, setDoc, Timestamp, where } from "firebase/firestore";
import { AUTH, DB } from "../../../auth/FirebaseContext";
import moment from "moment";

export async function addRepresentationOnFirestore(tableUid, orders, note) {
    let cashier = getSessionOperator().username
    let id = Date.now()
    for (let order of orders) {
        Object.keys(order).forEach(key => {
            if (order[key] === undefined) {
                order = {
                    ...order,
                    key: "",
                }
            }
        })
        let newItems = [];
        for (let item of order.items) {
            // eslint-disable-next-line no-loop-func
            Object.keys(item).forEach(key => {
                if (order[key] === undefined) {
                    item = {
                        ...item,
                        key: "",
                    }
                }
            })
            newItems.push(item)
        }
        order = {
            ...order,
            items: newItems
        }
    }
    return await setDoc(doc(DB, 'invoices', "representations", AUTH.currentUser.uid, id.toString()), {
        tableUid: tableUid || null,
        orders: orders,
        cashier,
        note: note || null,
        time: Timestamp.fromMillis(id),
    })
}

export async function getRepresentationsFromFirestoreForDate(startDate, endDate) {
    let arr = []
    const q = await query(collection(DB, `invoices/representations/${AUTH.currentUser.uid}`),
        where("time", ">=", Timestamp.fromDate(startDate)),
        where("time", "<=", Timestamp.fromDate(endDate)));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
        arr.push({
            ...doc.data(),
            uid: doc.id,
        })
    })
    return arr;
}

const foundCustomer = (cardId, customers) => {
    if (cardId && customers) {
        const found = customers.find(c => c.customerCardID == cardId)
        if(found){
            return `${found?.name} ${found?.surname}`
        }
        return "/"
    }
    return "/"
}

export async function getUnpaidOrdersFromFirestoreForDate(customers, startDate, endDate) {
    let arr = [];

    let q = query(collection(DB, `unpaidOrders/users/${AUTH.currentUser.uid}`));

    if (startDate && endDate) {
        q = query(q,
            where("orderTime", ">=", Timestamp.fromDate(startDate)),
            where("orderTime", "<=", Timestamp.fromDate(endDate))
        );
    }

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        arr.push({
            ...doc.data(),
            uid: doc.id,
        });
    });

    const tableData = arr.map(order => {
        const totalPrice = order.items.reduce((sum, item) => sum + (item.price * item.quantity), 0);

        const formatDate = (timestamp) => {
            if (!timestamp) return "/";
            // Proveri da li je timestamp Firestore Timestamp tip
            const date = timestamp instanceof Timestamp ? timestamp.toDate() : new Date(timestamp);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Meseci su 0-indeksirani
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        };

        return {
            items: order.items,
            cardId: order.customerCardNumber,
            customerName: foundCustomer(order.customerCardNumber, customers),
            date: formatDate(order.orderTime), // Ovdje pretpostavljamo da je orderTime Firestore Timestamp
            totalAmount: totalPrice,
            orderId: order.id
        };
    });

    return tableData;
}



