import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {child, get, push, update} from "firebase/database";
import {AUTH, DB, dbRef} from "../../auth/FirebaseContext";
import {doc, updateDoc} from "firebase/firestore";

export const fetchAllRFIDNFCustomers = createAsyncThunk('fetchAllRFIDNFCustomers', async () => {
    let RFIDNFCustomers = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/rfidNfcCustomers`))).forEach(RFIDNFCustomer => {
        RFIDNFCustomers.push({
            ...RFIDNFCustomer.val(),
            uid: RFIDNFCustomer.key,
        })
    })
    return RFIDNFCustomers
})

export const insertRFIDNFCustomer = createAsyncThunk('insertRFIDNFCustomer', async (payload) => {
    await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/rfidNfcCustomers`),
        payload);
    return payload;
})

export const moveOrderRFIDNFCustomer = createAsyncThunk('moveOrderRFIDNFCustomer', async (payload) => {
    const orderDocRef = doc(DB, "unpaidOrders", "users", AUTH.currentUser.uid, `${payload.orderId}`);

    await updateDoc(orderDocRef, {
        customerCardNumber: payload.customerCardNumber
    });
});

export const deleteRFIDNFCustomer = createAsyncThunk('deleteRFIDNFCustomer', async (payload) => {
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/rfidNfcCustomers/${payload.index}`),
        payload);
    return payload
})

const initialState = {
    RFIDNFCCustomers: [],
    loadingRFIDNFCCustomers: false,
    error: false
}

export const rfidNFCCustomersSlice = createSlice({
    name: 'RFIDNFCCustomers',
    initialState,
    extraReducers: {
        [fetchAllRFIDNFCustomers.pending]: (state) => {
            state.loadingRFIDNFCCustomers = true;
        },
        [fetchAllRFIDNFCustomers.fulfilled]: (state, action) => {
            state.RFIDNFCCustomers = action.payload
            state.loadingRFIDNFCCustomers = false;
        },
        [fetchAllRFIDNFCustomers.rejected]: (state) => {
            state.loadingRFIDNFCCustomers = false;
        },
        [insertRFIDNFCustomer.pending]: (state) => {
            state.loadingRFIDNFCCustomers = true;
        },
        [insertRFIDNFCustomer.fulfilled]: (state, {payload}) => {
            let arr = [...current(state.RFIDNFCCustomers)];
            arr.push(payload);
            state.RFIDNFCCustomers = arr;
            state.loadingRFIDNFCCustomers = false;
        },
        [insertRFIDNFCustomer.rejected]: (state) => {
            state.loadingRFIDNFCCustomers = false;
            state.error = undefined;
        },
        [deleteRFIDNFCustomer.pending]: (state) => {
            state.loading = true;
        },
        [deleteRFIDNFCustomer.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.RFIDNFCCustomers)]
            const index = arr.findIndex(i => i.uid === payload.uid)
            if (index !== -1) {
                arr[index] = payload
                state.RFIDNFCCustomers = arr
            }
            state.error = undefined
        },
        [deleteRFIDNFCustomer.rejected]: (state) => {
            state.loading = false;
        },
    }
})

export const rfidNFCCustomersReducer = rfidNFCCustomersSlice.reducer
