import React from "react"
import {Dialog, DialogContent, Stack, Typography} from "@mui/material";
// import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function LicenseExpiredDialog({open}){
    return(
        <Dialog open={open}>
            <Stack alignItems="center">
                {/*<PriorityHighIcon style={{color: "red", width: 50, height: 50}}/>*/}
                <Typography style={{fontWeight: "bold"}}>ESIR support</Typography>
            </Stack>
            <DialogContent>
                <Typography>
                    Vaša licenca je nekativna zbog neplaćanja.
                </Typography>
                <Typography>
                    Molimo Vas da se obratite Vašem distributeru.
                </Typography>
            </DialogContent>
        </Dialog>
    );
}
