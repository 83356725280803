// @mui
import {Alert, Box, Stack, Tooltip, Typography} from '@mui/material';
// hooks
import {useAuthContext} from '../../../auth/useAuthContext';
// layouts
import LoginLayout from '../../../layouts/login';
import AuthLoginForm from "./AuthLoginForm";


export default function LoginAuth0() {
    const {method} = useAuthContext();

    return (
        <LoginLayout>
            <Stack spacing={2} sx={{mb: 2, position: 'relative'}}>
                <Typography variant="h4">PRIJAVITE SE</Typography>

                <Tooltip title={method} placement="left">
                    <Box
                        component="img"
                        alt={method}
                        src={`/assets/icons/auth/ic_${method}.png`}
                        sx={{width: 32, height: 32, position: 'absolute', right: 0}}
                    />
                </Tooltip>
            </Stack>

            <Alert severity="info" sx={{mb: 3}}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize={14} variant="caption">
                        Tehnicka podrska dostupna :<strong>{` 24/7/365`}</strong>
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize={14} variant="caption">
                        Broj telefona: <strong>011/4444-588</strong>
                    </Typography>
                </Stack>
            </Alert>

            <AuthLoginForm/>

        </LoginLayout>
    );
}
