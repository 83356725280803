import PropTypes from "prop-types";
// @mui
import {useTheme} from "@mui/material/styles";
import {AppBar, IconButton, Stack, Toolbar, Typography} from "@mui/material";
// utils
import {bgBlur} from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import {HEADER, NAV} from "../../../config";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/Iconify";
import {useSettingsContext} from "../../../components/settings";
import AccountPopover from "./AccountPopover";
import useActiveLink from "../../../hooks/useActiveLink";
import {PATH_DASHBOARD} from "../../../routes/path";
//
import Searchbar from "./Searchbar";
import NotificationsPopover from "./NotificationsPopover";
import {getSessionOperator} from "../../../helper/session";
import {OPERATORS_GROUP} from "../../../constants";
import {keyframes} from "@mui/system";
import {useSelector} from "../../../redux/store";
import FullScreenOptions from "../nav/FullScreenOptions";
import UserInfo from "./UserInfo";

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func
};

const blink = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
`;

export default function Header({onOpenNav}) {
    const theme = useTheme();

    const {themeLayout} = useSettingsContext();

    const {isTrainingModActive} = useSelector(state => state.settings);

    const isNavHorizontal = themeLayout === "horizontal";

    const isNavMini = themeLayout === "mini";

    let isDesktop = useResponsive("up", "lg");
    let isHeader = useResponsive("up", "md");
    const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
    const {active} = useActiveLink(PATH_DASHBOARD.general.app);

    if (active) {
        isDesktop = false;
    }

    const operator = getSessionOperator();

    const renderContent = (
        <>
            {isDesktop && isNavHorizontal && <Logo sx={{mr: 2.5}}/>}

            {(!isDesktop && ![OPERATORS_GROUP.bar, OPERATORS_GROUP.chef, OPERATORS_GROUP.waiter].includes(operator.group)) && (
                <IconButton onClick={onOpenNav} sx={{mr: 1, color: "text.primary"}}>
                    <Iconify icon="eva:menu-2-fill"/>
                </IconButton>
            )}
            <Stack
                flexGrow={1} direction="column" alignItems="flex-start" justifyContent="center">
                {isHeader && <UserInfo/>}
                {isTrainingModActive && <Typography sx={{
                    position: "fixed",
                    top: 70,
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",

                    zIndex: 9999,
                    background: "rgba(255, 255, 255, 0.1)",
                    fontSize: "1.5rem",
                    color: "#ff5d52",
                    textAlign: "center",
                    animation: `${blink} 1.5s linear infinite;`
                }}>OBUKA MOD JE AKTIVAN</Typography>}
                {![OPERATORS_GROUP.bar, OPERATORS_GROUP.chef, OPERATORS_GROUP.waiter].includes(operator.group) &&
                    <Searchbar/>}


            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end"
                   spacing={{xs: 0.5, sm: 1.5}}>
                <FullScreenOptions showText={false}/>
                <NotificationsPopover/>

                <AccountPopover/>
            </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                boxShadow: "none",
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default
                }),
                transition: theme.transitions.create(["height"], {
                    duration: theme.transitions.duration.shorter
                }),
                ...(isDesktop && {
                    width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
                    height: HEADER.H_DASHBOARD_DESKTOP,
                    ...(isOffset && {
                        height: HEADER.H_DASHBOARD_DESKTOP_OFFSET
                    }),
                    ...(isNavHorizontal && {
                        width: 1,
                        bgcolor: "background.default",
                        height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`
                    }),
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`
                    })
                })
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: {lg: 5}
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}
