import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AUTH, DB} from "../../auth/FirebaseContext";
import {collection, getDocs, query} from "firebase/firestore";
import {storeErrorToOffline} from "../../store/offlineDb";
import moment from "moment";

export const fetchAllReservations = createAsyncThunk("fetchAllReservations", async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let arr = [];
            const q = query(collection(DB, `reservations/users/${AUTH.currentUser.uid}`));
            const querySnapshot = await getDocs(q);
            await querySnapshot.forEach((doc) => {
                let reservation = doc.data();
                arr.push(reservation)
            });
            resolve(arr);
        } catch (e) {
            storeErrorToOffline("fetchAllReservations", "redux/slices/reservations.js", [], e?.toString());
            reject(e);
        }
    });
});

export const fetchAllFreeTablesForReservation = createAsyncThunk("fetchAllFreeTablesForReservation", async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let reservations = [];
            const q = query(collection(DB, `reservations/users/${AUTH.currentUser.uid}`));
            const querySnapshot = await getDocs(q);
            await querySnapshot.forEach((doc) => {
                let reservation = doc.data();
                reservations.push(reservation)
            });
            const result =  await handleFindFreeTables(reservations,data)
            resolve(result);
        } catch (e) {
            storeErrorToOffline("fetchAllFreeTablesForReservation", "redux/slices/reservations.js", [], e?.toString());
            reject(e);
        }
    });
});

const handleFindFreeTables = async (reservations,data) => {
    const targetDate = moment(data.reservationTime);
    const startOfDay = targetDate.clone().startOf('day');
    const endOfDay = targetDate.clone().endOf('day');
    const tableUids = [];

    reservations.forEach((reservation) => {
        const bookingDate = moment.unix(reservation.time.seconds).add(reservation.time.nanoseconds / 1000000, 'milliseconds');
        const isSameDate = bookingDate.isBetween(startOfDay, endOfDay, null, '[]');
        if (isSameDate) {
            const timeDiff = moment.duration(targetDate.diff(bookingDate)).asMilliseconds();
            if (timeDiff <= 60 * 60 * 1000 && timeDiff >= -60 * 60 * 1000) {
                tableUids.push(reservation.tableUid);
            }
        }
    });
    const freeTables = await findFreeTables(tableUids, data)
    return freeTables
}

const findFreeTables = async (tableUids, data) => {
    const freeTables = [];
    for (let i = 0; i < data.layouts.length; i++) {
        const arr = []
        data.layouts[i].tables.forEach((table) => {
            if (!tableUids.includes(table.uid)) {
                arr.push({...table, layout: data.layouts[i].name});
            }
        });
        const obj = {}
        obj[data.layouts[i].name] = arr;
        freeTables.push(obj)
    }
    return freeTables;
}
const initialState = {
    freeTablesForReservation: [],
    reservations: [],
    loading: false
};

const slice = createSlice({
    name: "reservations",
    initialState,
    extraReducers: {
        [fetchAllReservations.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllReservations.fulfilled]: (state, {payload}) => {
            state.reservations = payload;
            state.loading = false;
        },
        [fetchAllReservations.rejected]: (state) => {
            state.loading = false;
        },
        [fetchAllFreeTablesForReservation.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllFreeTablesForReservation.fulfilled]: (state, {payload}) => {
            state.freeTablesForReservation = payload;
            state.loading = false;
        },
        [fetchAllFreeTablesForReservation.rejected]: (state) => {
            state.loading = false;
        },
    }
});

// Reducer
export default slice.reducer;