import {collection, onSnapshot, query} from "firebase/firestore";
import {AUTH, DB} from "../../../auth/FirebaseContext";
import {ORDER_TYPE} from "../../../constants";
import {dispatch} from "../../../redux/store";
import {addAlertToTable, fetchAllOrders, listenerActivated} from "../../../redux/slices/restaurant";
import {addActiveOrderChefPage} from "../../../redux/slices/chef";

export function addRestaurantListener(isWaiterIndexPage, orderType) {
    const q = query(collection(DB, `orders/users/${AUTH.currentUser.uid}`));
    onSnapshot(q, async (snapshot) => {
        for (const change of snapshot.docChanges()) {
            let order = change.doc.data();
            let parsedOrder = {
                id: parseInt(change.doc.id),
                active: order.active,
                alert: order.alert || false,
                finishedPreparation: order.finishedPreparation,
                items: order.items,
                isMobile: order.isMobile || false,
                submitted: order.submitted,
                tableUid: order.tableUid,
                orderType: order.orderType || ORDER_TYPE.all,
                cashier: order.cashier,
                userUid: AUTH.currentUser.uid,
                printed: order?.printed || false
            };

            if (change.type === "added") {
                dispatch(listenerActivated())
                // await addOrderOffline(parsedOrder);
                if (isWaiterIndexPage) {
                    dispatch(fetchAllOrders());
                }
            }
            // KADA KLIJENT ZELI DA NAPRAVI PORUDZBINU ALERT POLJE SE SETUJE NA ALERT TRUE
            // KADA KONOBAR PRIHVATI ILI ODBIJE SETUJE SE NA FALSE
            if (change.type === "modified") {
                dispatch(listenerActivated())
                // await updateOrderOffline(parsedOrder);
                if (parsedOrder) {
                    dispatch(addAlertToTable({
                        tableUid: parsedOrder.tableUid,
                        alert: parsedOrder.alert
                    }));
                }
                if (isWaiterIndexPage) {
                    dispatch(fetchAllOrders());
                }
            }
            if (change.type === "removed") {
                dispatch(listenerActivated())
                // await deleteOrderOffline(parsedOrder.id);
                if (isWaiterIndexPage) {
                    dispatch(fetchAllOrders())
                }
            }
            dispatch(addActiveOrderChefPage({
                order: parsedOrder,
                type: change.type,
                orderType: orderType
            }));
        }
    });
}