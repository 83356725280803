import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { child, get, push, update } from "firebase/database";

export const fetchAllDiscounts = createAsyncThunk("fetchAllDiscounts", async () => {
  const arr = [];
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/discounts`))).forEach(child1 => {
    arr.push({
      ...child1.val(),
      uid: child1.key
    });
  });
  return arr;
});

export const addDiscount = createAsyncThunk("addDiscount", async (payload) => {
  delete payload.avatarUrl;
  const costKey = await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/discounts`),
    { ...payload, inactive: false });
  return { ...payload, uid: costKey.key };
});

export const updateDiscount = createAsyncThunk("updateDiscount", async (payload) => {
  delete payload.avatarUrl;
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/discounts/${payload.uid}`),
    { ...payload, inactive: false });
  return { ...payload, inactive: false };
});

export const deleteDiscount = createAsyncThunk("deleteDiscount", async (payload) => {
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/discounts/${payload.uid}`),
    { ...payload, inactive: !payload.inactive });
  return {
    ...payload,
    inactive: !payload.inactive
  };
});

const initialState = {
  discountsList: [],
  loading: false,
  discount: undefined
};

export const discountsSlice = createSlice({
  name: "discounts",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllDiscounts.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllDiscounts.fulfilled]: (state, { payload }) => {
      state.discountsList = payload;
      state.loading = false;
    },
    [fetchAllDiscounts.rejected]: (state) => {
      state.loading = false;
    },
    [addDiscount.pending]: (state) => {
      state.loadingCosts = true;
    },
    [addDiscount.fulfilled]: (state, { payload }) => {
      let arr = [...current(state.discountsList)];
      arr.push(payload);
      state.discountsList = arr;
      state.loadingCosts = false;
    },
    [addDiscount.rejected]: (state) => {
      state.loadingCosts = false;
    },
    [updateDiscount.pending]: (state) => {
      state.loadingCosts = true;
    },
    [updateDiscount.fulfilled]: (state, { payload }) => {
      let arr = [...current(state.discountsList)];
      const index = arr.findIndex(i => i.uid === payload.uid);
      if (index !== -1) {
        arr[index] = payload;
        state.discountsList = arr;
      }
      state.loadingCosts = false;
    },
    [updateDiscount.rejected]: (state) => {
      state.loadingCosts = false;
    },
    [deleteDiscount.pending]: (state) => {
      state.loading = true;
    },
    [deleteDiscount.fulfilled]: (state, { payload }) => {
      const arr = [...current(state.discountsList)];
      const index = arr.findIndex(i => i.uid === payload.uid);
      if (index !== -1) {
        arr[index] = payload;
        state.discountsList = arr;
      }
      state.error = undefined;
    },
    [deleteDiscount.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export const discountsReducer = discountsSlice.reducer;
