import axios from "axios";

const ESDC_URL = "http://127.0.0.1:8888/api/v3/"

const customAxios = axios.create({
  baseURL: ESDC_URL,
  headers: {
    "Accept": "application/json"
  },
  withCredentials: false,
});

export function getTaxRates() {
  return customAxios.get(`tax-rates`);
}

export function getStatusLpfr() {
  return customAxios.get('status')
}

export function postStatusPin(pin) {
  return customAxios.post("pin", pin)
}

export function postInvoiceLpfr(data) {
  let sendData = {...data, options:{...data.options, OmitQRCodeGen: 0}}
  return customAxios.post("invoices", sendData)
}

export function getInvoice(requestId) {
  return customAxios.get(`invoices/${requestId}`)
}

export function getAllPrintersLpfr(){
  return customAxios.get("/printer/all")
}

export function getDefaultPrinterLpfr(){
  return customAxios.get("/printer/default")
}

export function getDefaultPrinterSizeLpfr(){
  return customAxios.get("/printer/get-size")
}

export function setDefaultPrinterLpfr(printerName){
  return customAxios.post("/printer/set-printer", {
    printerName: printerName
  })
}
export function setDefaultPrinterSizeLpfr(size){
  return customAxios.post("/printer/set-size", {
    size: size
  })
}

export function printPdfLpfr(data){
  return customAxios.post("/printer/print", data)
}
