import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { child, get, push, remove, set } from "firebase/database";

export const fetchAllIngredientsCategories = createAsyncThunk('fetchAllIngredientsCategories', async () => {
    let arr = [];
    (await get(child(dbRef, `/public/categories/ingredients`))).forEach((child1) => {
        arr.push(child1.val())
    });
    return arr;
})

export const fetchAllIngredients = createAsyncThunk('fetchAllIngredients', async () => {
    let arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients`))).forEach((child1) => {
        arr.push({
            ...child1.val(),
            uid: child1.key,
        })
    });
    return arr;
})

export const addNewIngredient = createAsyncThunk("addNewIngredient", async (data) => {
    if (data.uid) {
        return set(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${data.uid}`),
            {
                name: data.name || "",
                category: data.category || "",
                unit: data.unit || "",
                quantity: data.quantity || 0,
                vat: data.vat || "",
                uid: data.uid
            });
    } else {
        return push(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients`),
            {
                name: data.name || "",
                category: data.category || "",
                unit: data.unit || "",
                quantity: data.quantity || 0,
                vat: data.vat || ""
            });
    }
})

export const updateIngredient = createAsyncThunk("updateIngredient", async (data) => {
    return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${data.uid}`),
        {
            name: data.name || "",
            category: data.category || "",
            unit: data.unit || "",
            quantity: data.quantity || 0,
            vat: data.vat || ""
        });
})

export const removeIngredient = createAsyncThunk("removeIngredient", async (data) => {
    return await remove(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${data.uid}`));
})

const initialState = {
    ingredients: [],
    categories: [],
    loading: false
};

const slice = createSlice({
    name: "ingredients",
    initialState,
    extraReducers: {
        [addNewIngredient.fulfilled]: (state, {meta, payload}) => {
            let arr = [...current(state.ingredients)]
            arr.push({
                ...meta.arg,
                uid: payload?.key || meta?.arg?.uid
            })
            state.ingredients = arr;
        },
        // updateIngredient
        [updateIngredient.fulfilled]: (state, {meta, payload}) => {
            let arr = [...current(state.ingredients)]
            let index = arr.findIndex(item => item.id === meta.arg.id)
            if (index !== -1) {
                arr[index] = meta.arg
            }
            state.ingredients = arr;
        },
        // fetchAllIngredients
        [fetchAllIngredients.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllIngredients.fulfilled]: (state, {payload}) => {
            state.ingredients = payload;
            state.loading = false;
        },
        [fetchAllIngredients.rejected]: (state) => {
            state.loading = false;
        },
        // fetchAllIngredientsCategories
        [fetchAllIngredientsCategories.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllIngredientsCategories.fulfilled]: (state, {payload}) => {
            state.categories = payload;
            state.loading = false;
        },
        [fetchAllIngredientsCategories.rejected]: (state) => {
            state.loading = false;
        },
        // removeIngredient
        [removeIngredient.pending]: (state) => {
            state.loading = true
        },
        [removeIngredient.fulfilled]: (state, {meta}) => {
            let arr = [...current(state.ingredients)]
            let index = arr.findIndex(item => item.id === meta.arg.id)
            if (index !== -1) {
                arr.splice(index, 1);
            }
            state.ingredients = arr;
            state.loading = false
        },
        [removeIngredient.rejected]: (state) => {
            state.loading = true
        },
    }
});

// Reducer
export default slice.reducer;