import {collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc} from "firebase/firestore";
import {AUTH, DB} from "../../../auth/FirebaseContext";
import {putLogToFirebase} from "../../../store/offlineDb";
import {CHANGE_ITEM_QUANTITY, ORDER_TYPE} from "../../../constants";
import {changeItemQuantity} from "../../realtimeDatabase";
import {dispatch} from "../../../redux/store";
import {addWriteOffToFirestore} from "../writeOff";
import {checkRestaurantGroups} from "../../other";
import {getSessionOperator} from "../../session";

export async function updateOrderTypeAndFinishPreparation(orderId, orderType, finishedPreparation) {
    updateDoc(doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`), {
        orderType: orderType,
        finishedPreparation: finishedPreparation
    }).then(_ => {
        console.debug("Uspesno apdejtovan ORDER na firestoru");
    }).catch(e => {
        putLogToFirebase("updateFinishPreparation", "firestore/useTableClick.js",
            [orderId, orderType, finishedPreparation], e.toString())
    })
}

export async function updateFinishPreparation(orderId) {
    updateDoc(doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`), {
        finishedPreparation: true,
        seen: false,
        alert: true
    }).then(_ => {
        console.debug("Uspesno apdejtovan ORDER na firestoru");
    }).catch(e => {
        putLogToFirebase("updateFinishPreparation", "firestore/useTableClick.js",
            [orderId], e.toString())
    })
}

export async function removeProductSplitPaymentFB(tableUid, item) {
    try {
        const ordersCollection = collection(DB, `orders/users/${AUTH.currentUser.uid}`);
        const querySnapshot = await getDocs(ordersCollection);

        let orders = [];
        querySnapshot.forEach((doc) => {
            let table = doc.data();
            const operator = getSessionOperator().username
            if (table.cashier === operator &&
                table.tableUid === tableUid &&
                table.submitted === true
            ) {
                orders.push(table);
            }
        });
        let quantity = item.quantity;
        let filteredOrders = orders.filter(order => order.items.some(orItem => orItem.uid === item.uid));
        for (const order of filteredOrders) {
            if (quantity > 0) {
                let orderedItems = [...order.items];
                let index = orderedItems.findIndex(orItem => orItem.uid === item.uid);
                if (index !== -1) {
                    let isBellow = orderedItems[index].quantity <= quantity;
                    orderedItems[index].quantity = isBellow ? 0 : orderedItems[index].quantity - quantity;
                    quantity = quantity - orderedItems[index].quantity;
                    if (orderedItems[index].quantity < 1) {
                        orderedItems.splice(index, 1);
                    }
                    await updateOrderOnline({id: order.id, items: orderedItems})
                    if (orderedItems.length === 0) {
                        await updateActive(order.id, false);
                    }
                    if (quantity === 0) {
                        return;
                    }
                }
            }
        }
    } catch (error) {
        console.error("Error fetching active orders: ", error);
        return [];
    }
}

export async function getAllSubmittedOrders(isPrepared) {
    try {
        const ordersCollection = collection(DB, `orders/users/${AUTH.currentUser.uid}`);
        const querySnapshot = await getDocs(ordersCollection);

        let orders = [];
        querySnapshot.forEach((doc) => {
            let table = doc.data();
            if (
                table.submitted === true &&
                (table.orderType === ORDER_TYPE.all || table.orderType === ORDER_TYPE.kitchen) &&
                table.finishedPreparation === isPrepared
            ) {
                orders.push(table);
            }
        });
        return orders;
    } catch (error) {
        console.error("Error fetching active orders: ", error);
        return [];
    }
}

export async function getAllSubmittedOrdersForBar() {
    try {
        const ordersCollection = collection(DB, `orders/users/${AUTH.currentUser.uid}`);
        const querySnapshot = await getDocs(ordersCollection);

        let orders = [];
        querySnapshot.forEach((doc) => {
            let table = doc.data();
            if (
                table.submitted === true &&
                (table.orderType === ORDER_TYPE.all || table.orderType === ORDER_TYPE.bar)
            ) {
                orders.push(table);
            }
        });
        return orders;
    } catch (error) {
        console.error("Error fetching active orders: ", error);
        return [];
    }
}

export async function getAllOrders() {
    try {
        const ordersCollection = collection(DB, `orders/users/${AUTH.currentUser.uid}`);
        const querySnapshot = await getDocs(ordersCollection);

        let orders = [];
        querySnapshot.forEach((doc) => {
            let table = doc.data();
            if (
                table.active === true) {
                orders.push(table);
            }
        });
        return orders;
    } catch (error) {
        console.error("Error fetching active orders: ", error);
        return [];
    }
}

export async function updateOrderOnline(order) {
    try {
        const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/orders`, order.id);

        await updateDoc(docRef, order);

        return true;
    } catch (error) {
        console.error("Error updating order: ", error);
        return false;
    }
}

export async function getAllActiveOrdersByTableIdFirebase(tableUid) {
    try {
        const ordersCollection = collection(DB, `orders/users/${AUTH.currentUser.uid}`);
        const querySnapshot = await getDocs(ordersCollection);
        let orders = [];
        querySnapshot.forEach((doc) => {
            let table = doc.data();
            if (
                (table.active === true) &&
                table.tableUid === tableUid) {

                orders.push(table);
            }
        });
        return orders;
    } catch (error) {
        console.error("Error fetching active orders: ", error);
        return [];
    }
}

export async function transferOrderToAnotherTableFirestore(order, tableUid) {
    const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/${order.id}`);
    await updateDoc(docRef, {
        ...order,
        tableUid: tableUid
    });
}

export async function deleteOnlineOrderById(id) {
    await deleteDoc(doc(DB, `orders/users/${AUTH.currentUser.uid}/${id}`));
}

export async function removeProductByOrderIdFB(orderId, item, flag) {
    try {
        const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`);
        const onlineOrder = (await getDoc(docRef)).data();
        if (onlineOrder) {
            let orderedItems = [...onlineOrder.items];
            let index = orderedItems.findIndex(orItem => orItem.uid === item.uid);
            if (index !== -1) {
                if (flag) {
                    orderedItems[index].quantity = orderedItems[index].quantity - item.quantity;
                }
                if (orderedItems[index].quantity < 1) {
                    orderedItems.splice(index, 1);
                }
            }
            if (orderedItems.length === 0) {
                await deleteOnlineOrderById(orderId);
            } else {
                await updateDoc(docRef, {
                    ...onlineOrder,
                    items: orderedItems
                })
            }
        }
    } catch {

    }
}

export async function deleteOnlineOrder(order) {
    await deleteDoc(doc(DB, `orders/users/${AUTH.currentUser.uid}/${order.id}`));
}

export async function addDeletedOnlineOrder(order) {
    await setDoc(doc(DB, "deletedOrders", "users", AUTH.currentUser.uid, order.id.toString()), {...order});
}

export async function updateActive(orderId, active) {
    await updateDoc(doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`), {
        active: active
    });
}

export async function updateSeen(ids) {
    for (const orderId of ids) {
        const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`);
        await updateDoc(docRef, {
            seen: true,
            alert: false
        });
    }
}

export async function addOrderOnFirestore(order) {
    //addedQuantity1
    const obj = {...order};
    const items = obj.items.map(item => ({
        ...item,
        //submitted: true, TODO IZMENIO
        quantity: item.quantity
    }));
    obj.items = items
    const orderType = checkRestaurantGroups(obj)
    await setDoc(doc(DB, "orders", "users", AUTH.currentUser.uid, order.id.toString()),
        {...obj, submitted: true, seen: false, orderType: orderType});
}

export async function writeOffProductFromOrder(orderId, itemUid, quantity, isWriteOff) {

    await reduceItemQuantityForOrder(orderId, itemUid, quantity);
    //await deleteOnlineOrderById(orderId);
    // await removeProductByOrderId(orderId, {
    //     uid: itemUid,
    //     quantity: quantity,
    //     //addedQuantity: quantity
    // }, false);
    if (isWriteOff) {
        await changeItemQuantity(itemUid, quantity, CHANGE_ITEM_QUANTITY.reduce, true, dispatch);
        await addWriteOffToFirestore(orderId, itemUid, quantity);
    }
}


// **** SMANJUJE QTY OD PROIZVODA NA PORUDZBINI **** //
export async function reduceItemQuantityForOrder(orderId, itemUid, quantity) {
    const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`);
    const onlineOrder = (await getDoc(docRef)).data();
    let items = [...onlineOrder.items];
    let index = items.findIndex(orItem => orItem.uid === itemUid);
    if (index !== -1) {
        let newQuantity = items[index].quantity - quantity;
        if (newQuantity <= 0) {
            items.splice(index, 1);
        } else {
            items[index] = {
                ...items[index],
                quantity: newQuantity,
                //addedQuantity: newQuantity
            };
        }
    }
    await updateDoc(docRef, {
        active: items.length !== 0,
        items: items
    });
}

export async function updateItemNoteForOrder(orderId, item) {
    const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`);
    const onlineOrder = (await getDoc(docRef)).data();
    let items = [...onlineOrder.items];
    let index = items.findIndex(orItem => orItem.uid === item.uid);
    if (index !== -1) {
        items[index] = {
            ...items[index],
            note: item.note
        };
    }
    await updateDoc(docRef, {
        items: items
    });
}