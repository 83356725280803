import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import {child, get} from "firebase/database";
import {dbRef} from "../../auth/FirebaseContext";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    groups: [],
};

const slice = createSlice({
    name: 'groupsPublic',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET GROUPS
        getLabelsSuccess(state, {payload}) {
            state.isLoading = false;
            state.groups = payload;
        }
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
const GROUPS_IDS_OPERATORS = [0, 10, 9, 8]

export function getAllPublicGroups() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let arr = [];
            (await get(child(dbRef, 'public/groups'))).forEach(child1 => {
                if (GROUPS_IDS_OPERATORS.includes(parseInt(child1.key))) {
                    arr.push({
                        ...child1.val(),
                        uid: parseInt(child1.key)
                    })
                }
            })
            dispatch(slice.actions.getLabelsSuccess(arr))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}


