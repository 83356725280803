import PropTypes from 'prop-types';
// @mui
import {Stack, Typography} from '@mui/material';
// components
import Image from '../../components/image';
//
import {StyledContent, StyledRoot, StyledSection, StyledSectionBg} from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    illustration: PropTypes.string,
};

export default function LoginLayout({children, illustration, title}) {
    return (
        <StyledRoot>
            <Stack direction="row" width={1}>
                <StyledSection>
                    <Typography variant="h3" color="dimgray" sx={{mt: 5, textAlign: 'center'}}>
                        {title || 'Zdravo, dobrodošli nazad!'}
                    </Typography>

                    <Image
                        disabledEffect
                        visibleByDefault
                        alt="auth"
                        src={illustration || '/assets/waiter.png'}
                        sx={{maxWidth: 380}}
                    />

                    <StyledSectionBg/>
                </StyledSection>
                <StyledContent>
                    <Stack sx={{width: 1}}> {children} </Stack>
                </StyledContent>
            </Stack>
        </StyledRoot>
    );
}
