import { calculateBasic, calculateBasicOfItem, isArrayContainsTax } from "../other";

export const addRefundTaxItemsFromInvoiceToArray = (array, invoice, isRefound = false) => {
  let taxes = invoice.taxItems;
  let length = invoice.taxItems ? taxes.length : 0;
  for (let i = 0; i < length; i++) {
    let indexOfItem = isArrayContainsTax(array, taxes[i]);
    if (indexOfItem === -1) {
      array.push({
        amount: isRefound ? -taxes[i].amount : taxes[i].amount,
        categoryName: taxes[i].categoryName,
        categoryType: taxes[i].categoryType,
        label: taxes[i].label,
        rate: taxes[i].rate
      });
    } else {
      if (!isRefound) {
        array[indexOfItem].amount = parseFloat(array[indexOfItem].amount + parseFloat(taxes[i].amount));
      }
    }
  }
};

export const addTaxItemsFromInvoiceToArray = (array, invoice, isRefound = false) => {
  let taxes = invoice.taxItems;
  let length = invoice.taxItems ? taxes.length : 0;
  for (let i = 0; i < length; i++) {
    let indexOfItem = isArrayContainsTax(array, taxes[i]);
    if (indexOfItem === -1) {
      array.push({
        amount: isRefound ? -taxes[i].amount : taxes[i].amount,
        categoryName: taxes[i].categoryName,
        categoryType: taxes[i].categoryType,
        label: taxes[i].label,
        rate: taxes[i].rate
      });
    } else {
      if (isRefound) {
        array[indexOfItem].amount = parseFloat(array[indexOfItem].amount - parseFloat(taxes[i].amount));
      } else {
        array[indexOfItem].amount = parseFloat(array[indexOfItem].amount + parseFloat(taxes[i].amount));
      }
    }
  }
};

export function calculatePdv(items, currentTaxRates) {
  let sum = 0;
  for (let i = 0; i < items.length; i++) {
    sum += parseFloat(items[i].unitPrice) * parseFloat(items[i].quantity) - calculateBasicOfItem(items[i], currentTaxRates);
  }
  return sum;
}

export const addPaymentMethodFromInvoiceToArray = (array, invoice, isRefound = false) => {
  try {
    invoice.paymentMethod.forEach(paymentMethod => {
      let indexOfMethod = array.findIndex((method) => method.paymentType === paymentMethod.paymentType);
      if (indexOfMethod === -1) {
        if (isRefound) {
          array.push({ paymentType: paymentMethod.paymentType, amount: parseFloat(-paymentMethod.amount) });
        } else {
          array.push({ paymentType: paymentMethod.paymentType, amount: parseFloat(paymentMethod.amount) });
        }
      } else {
        if (isRefound) {
          array[indexOfMethod].amount = parseFloat(array[indexOfMethod].amount) - parseFloat(paymentMethod.amount);
        } else {
          array[indexOfMethod].amount = parseFloat(array[indexOfMethod].amount) + parseFloat(paymentMethod.amount);
        }

      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const addRealPaymentMethodFromInvoiceToArray = (arrayRealPayments, invoice, isRefound = false) => {
  try {
    if(invoice?.internalData?.realPayments?.length > 0){
      invoice.internalData.realPayments?.forEach(paymentMethod => {
        let indexOfMethod = arrayRealPayments.findIndex((method) => method.paymentType === paymentMethod.paymentType);
        if (indexOfMethod === -1) {
          if (isRefound) {
            arrayRealPayments.push({ paymentType: paymentMethod.paymentType, amount: parseFloat(-paymentMethod.amount) });
          } else {
            arrayRealPayments.push({ paymentType: paymentMethod.paymentType, amount: parseFloat(paymentMethod.amount) });
          }
        } else {
          if (isRefound) {
            arrayRealPayments[indexOfMethod].amount = parseFloat(arrayRealPayments[indexOfMethod].amount) - parseFloat(paymentMethod.amount);
          } else {
            arrayRealPayments[indexOfMethod].amount = parseFloat(arrayRealPayments[indexOfMethod].amount) + parseFloat(paymentMethod.amount);
          }

        }
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const addItemFromInvoiceToArrayByTax = (array, items, currentTaxRates) => {
  try {
    items.forEach(item => {
      let index = array.findIndex(taxItem => {
        return taxItem.label === item.labels[0];
      });
      let basic = calculateBasic([{
        unitPrice: item.totalAmount,
        quantity: 1,
        vat: item.labels[0]
      }], currentTaxRates);
      if (index === -1) {
        array.push({
          label: item.labels[0],
          totalAmount: parseFloat(item.totalAmount),
          basic: basic
        });
      } else {
        array[index].totalAmount = parseFloat(array[index].totalAmount) + parseFloat(item.totalAmount);
        array[index].basic = parseFloat(array[index].basic) + parseFloat(basic);
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const addItemFromInvoiceToArray = (array, invoice, isRefound = false, currentTaxRates) => {
  try {
    invoice.items.forEach(item => {
      let indexOfItem = array.findIndex(localItem => localItem.name === item.name);
      let basic = calculateBasic([{
        unitPrice: item.totalAmount,
        quantity: 1,
        vat: item.labels[0]
      }], currentTaxRates);
      let tax = calculatePdv([{
        unitPrice: item.totalAmount,
        quantity: 1,
        vat: item.labels[0]
      }], currentTaxRates);
      if (indexOfItem === -1) {
        array.push({
          uid: item.uid,
          name: item.name,
          basic: isRefound ? -basic : basic,
          tax: tax,
          itemGroup: item?.restaurantGroup,
          itemCategory: item?.restaurantItemsCategory,
          unitPrice: item.unitPrice,
          totalAmount: parseFloat(item.totalAmount),
          quantity: parseFloat(item.quantity),
          labels: item.labels,
          multiplePrices: [{
            unitPrice: item.unitPrice,
            quantity: parseFloat(item.quantity),
            totalAmount: parseFloat(item.totalAmount)
          }]
        });
      } else {
        array[indexOfItem].totalAmount = parseFloat(array[indexOfItem].totalAmount) + parseFloat(item.totalAmount);
        array[indexOfItem].quantity = parseFloat(array[indexOfItem].quantity) + parseFloat(item.quantity);
        if (isRefound) {
          array[indexOfItem].basic = parseFloat(array[indexOfItem].basic) - parseFloat(basic);
        } else {
          array[indexOfItem].basic = parseFloat(array[indexOfItem].basic) + parseFloat(basic);
        }
        array[indexOfItem].tax = parseFloat(array[indexOfItem].tax) + parseFloat(tax);
        let index = array[indexOfItem].multiplePrices.findIndex((multipleItem) => multipleItem.unitPrice === item.unitPrice);
        if (index === -1) {
          let arr = [...array[indexOfItem].multiplePrices];
          arr.push({
            unitPrice: item.unitPrice,
            quantity: parseFloat(item.quantity),
            totalAmount: parseFloat(item.totalAmount)
          });
          array[indexOfItem].multiplePrices = arr;
        } else {
          array[indexOfItem].multiplePrices[index].totalAmount =
            parseFloat(array[indexOfItem].multiplePrices[index].totalAmount) +
            parseFloat(item.totalAmount);
          array[indexOfItem].multiplePrices[index].quantity =
            parseFloat(array[indexOfItem].multiplePrices[index].quantity) +
            parseFloat(item.quantity);
        }
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const addCashiersAmountFromInvoiceToArray = (array, invoice, isRefound = false) => {
  try {
    let cashierIndex = array.findIndex((item) => item.name === invoice.cashier);
    if (cashierIndex === -1) {
      array.push({
        name: invoice.cashier,
        amount: isRefound ? 0 : parseFloat(invoice.totalAmount),
        refund: isRefound ? parseFloat(invoice.totalAmount) : 0
      });
    } else {
      if (isRefound) {
        array[cashierIndex].refund = parseFloat(array[cashierIndex].refund) + parseFloat(invoice.totalAmount);
      } else {
        array[cashierIndex].amount = parseFloat(array[cashierIndex].amount) + parseFloat(invoice.totalAmount);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const addCashiersAmountFromInvoiceForProductToArray = (array, product, invoice, isRefound = false) => {
  try {
    let cashierIndex = array.findIndex((item) => item.name === invoice.cashier);
    let items = invoice.items;
    items = items.filter(lItem => lItem.uid === product.uid || lItem.name.includes(product.name));
    let amount = items.reduce((a, { totalAmount }) => a + totalAmount, 0);
    if (cashierIndex === -1) {
      array.push({
        name: invoice.cashier,
        amount: isRefound ? 0 : parseFloat(amount),
        refund: isRefound ? parseFloat(amount) : 0
      });
    } else {
      if (isRefound) {
        array[cashierIndex].refund = parseFloat(array[cashierIndex].refund) + parseFloat(amount);
      } else {
        array[cashierIndex].amount = parseFloat(array[cashierIndex].amount) + parseFloat(amount);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const concatItemsForTax = (taxItems, amountByTax) => {
  let arr = [];
  taxItems.forEach(taxItem => {
    let index = amountByTax.findIndex(aTax => aTax.label === taxItem.label);
    if (index !== -1) {
      arr.push({
        ...taxItem,
        basic: amountByTax[index].basic,
        totalAmount: amountByTax[index].totalAmount
      });
    } else {
      arr.push({
        ...taxItem,
        basic: "/",
        totalAmount: "/"
      });
    }
  });
  return arr;
};
