import Router from "./routes";
import ThemeProvider from "./theme";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { ThemeSettings } from "./components/settings";
import SnackbarProvider from "./components/snackbar";
import { useEffect } from "react";
import Dexie from "dexie";
import { createDB } from "./store/offlineDb";
import { WaiterSessionProvider } from "./context/Waiter";
import { LoadingProvider } from "./context/Loading";


function App() {

    useEffect(() => {
        Dexie.exists("ESIR_KELNER_V2").then(exists => {
            if (!exists) {
                createDB();
            }
        }).catch(reason => {
            console.error(reason)
        })
    }, [])


    return (
        <MotionLazyContainer>
            <ThemeProvider>
                <ThemeSettings>
                    <SnackbarProvider>
                        <LoadingProvider>
                            <WaiterSessionProvider>
                                <Router/>
                            </WaiterSessionProvider>
                        </LoadingProvider>
                    </SnackbarProvider>
                </ThemeSettings>
            </ThemeProvider>
        </MotionLazyContainer>
    );
}

export default App;
