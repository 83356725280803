import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {AUTH, DB, dbRef} from "../../auth/FirebaseContext";
import {collection, deleteDoc, doc, getDocs, query, setDoc} from "firebase/firestore";
import {child, get, push, set, update} from "firebase/database";
import {putCostOffline, putIngredientOffline, putItemOffline, storeErrorToOffline} from "../../store/offlineDb";
import {changeItemQuantity} from "../../helper/realtimeDatabase";
import {CHANGE_ITEM_QUANTITY} from "../../constants";
import moment from "moment/moment";
import {formatQuantity} from "../../utils/other";
import {getSessionOperator} from "../../helper/session";

export const fetchAllFactures = createAsyncThunk("fetchAllFactures", async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let arr = [];
            const q = query(collection(DB, `factures/users/${AUTH.currentUser.uid}`));
            const querySnapshot = await getDocs(q);
            await querySnapshot.forEach((doc) => {
                let facture = doc.data();
                let paidAmount = facture.payments ? facture.payments?.reduce((acc, obj) => {
                    return acc + obj.amount;
                }, 0) : 0;
                let itemsSum = facture?.items?.reduce((aux, {purchasePrice, quantity, discount}) => {
                    return aux + ((purchasePrice * quantity) - ((purchasePrice * quantity) * (discount / 100)));
                }, 0);
                let costSum = facture?.costs?.reduce((aux, {purchasePrice, quantity, discount}) => {
                    return aux + ((purchasePrice * quantity) - ((purchasePrice * quantity) * (discount / 100)));
                }, 0);
                let totalAmount = parseFloat(parseFloat(itemsSum + costSum).toFixed(2));
                arr.push({
                    ...facture,
                    paidAmount,
                    totalAmount,
                    dateOfIssue: facture.dateOfIssue.toDate(),
                    paymentDate: facture.paymentDate.toDate(),
                    dateOfInvoiceReceipt: facture.dateOfInvoiceReceipt.toDate(),
                    uid: doc.id
                });
            });
            resolve(arr);
        } catch (e) {
            storeErrorToOffline("fetchAllFactures", "redux/slices/factures.js", [], e?.toString());
            reject(e);
        }
    });
});

export const addFactureRegistered = createAsyncThunk("addFactureRegistered", async (data) => {
    await setDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`),
        {...data, registered: !data.registered});
    return {...data, registered: !data.registered};
});

export const updateFacture = createAsyncThunk("updateFacture", async (data) => {
    await setDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`), data);
});

export const removeFacturePayment = createAsyncThunk("removeFacturePayment", async (data) => {
    await setDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`), data);

});

export const deleteFacture = createAsyncThunk("deleteFacture", async (data) => {
    await deleteDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`));
    for (const item of data.items) {
        if (item?.isItem === false) {
            let ingItem = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${item.uid}`))).val();
            await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${item.uid}`),
                {
                    ...ingItem,
                    quantity: parseFloat((parseFloat(ingItem.quantity) - parseFloat(item.quantity)).toFixed(3))
                });
        } else {
            await changeItemQuantity(item.uid, item.quantity, CHANGE_ITEM_QUANTITY.reduce, item.isItem);
        }
    }
    return data;
});

export const createFacture = createAsyncThunk("createFacture", async (data) => {
    const {items, ingredients, costs} = data;
    const copyItems = []
    const copyIngredients = []
    const copyCosts = []
    let timestamp = moment(new Date()).format("X");
    const factureItems = [];
    const itemsIds = [];
    for (const item of items) {
        if (item.isNew) {
            await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`), {
                name: item.name,
                category: item.category,
                description: item.description,
                vat: item.vat,
                unit: item.unit,
                code: item.code,
                price: item.price,
                ean: item.ean,
                quantity: item.quantity,
                restaurantGroup: item.restaurantGroup,
                restaurantItemsCategory: item.restaurantItemsCategory
            }).then(value => {
                copyItems.push({...item, uid: value.key})
                putItemOffline({...item, uid: value.key});
                factureItems.push({
                    description: item.description,
                    discount: item.discount,
                    isItem: true,
                    uid: value.key,
                    purchasePrice: item.purchasePrice,
                    quantity: item.quantity
                });
            });
        } else {
            copyItems.push(item)
        }
    }

    for (const ingredient of ingredients) {
        if (ingredient.isNew) {
            await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients`), {
                name: ingredient.name,
                category: ingredient.category,
                unit: ingredient.unit,
                vat: ingredient.vat,
                quantity: ingredient.quantity
            }).then(value => {
                copyIngredients.push({...ingredient, uid: value.key})
                putIngredientOffline({...ingredient, uid: value.key});
                factureItems.push({
                    description: ingredient.description,
                    discount: ingredient.discount,
                    isItem: false,
                    uid: value.key,
                    purchasePrice: ingredient.purchasePrice,
                    quantity: ingredient.quantity
                });
            });
        } else {
            copyIngredients.push(ingredient)
        }
    }

    for (const cost of costs) {
        if (cost.isNew) {
            await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs`), {
                name: cost.name,
                group: cost.group,
                unit: cost.unit,
                vat: cost.vat,
                price: cost.price,
                quantity: cost.quantity
            }).then(value => {
                copyCosts.push({...cost, uid: value.key})
                putCostOffline({...cost, uid: value.key});
                factureItems.push({
                    discount: cost.discount,
                    uid: value.key,
                    purchasePrice: cost.price,
                    quantity: cost.quantity
                });
            });
        } else {
            copyCosts.push(cost)
        }
    }
    // delete facture.id

    const mergeOldWithNewItems = copyItems.concat(copyIngredients, copyCosts);
    const itemsAndIngredients = mergeOldWithNewItems.filter(i => i.isItem || i.isIngredient);
    mergeOldWithNewItems.forEach(item => {
        if (item.itemUid) {
            itemsIds.push(item.itemUid);
        }
        if (item.uid) {
            itemsIds.push(item.uid);
        }
        if (item.ingredientUid) {
            itemsIds.push(item.ingredientUid);
        }
        if (item.costUid) {
            itemsIds.push(item.costUid);
        }
    });
    const finalObj = {...data};
    finalObj.items = itemsAndIngredients;
    delete finalObj.ingredients;

    let newFacture = {
        ...finalObj,
        uid: Number(timestamp),
        itemsIds,
        operatorUid: getSessionOperator().uid
    };
    const factureRef = doc(DB, "factures", "users", AUTH.currentUser.uid, timestamp);
    await setDoc(factureRef, newFacture, {merge: true});
    // await putFactureOffline(newFacture)
    for (const fitem of copyIngredients) {
        if (fitem?.isNew !== true) {
            const ing = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${fitem.uid}`))).val();
            await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${fitem.uid}`),
                {
                    name: ing.name,
                    category: ing.category,
                    unit: ing.unit,
                    vat: ing.vat,
                    quantity: formatQuantity(formatQuantity(fitem.quantity) + formatQuantity(ing.quantity))
                });
        }
    }
    for (const fitem of copyCosts) {
        if (fitem?.isNew !== true) {
            const cst = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${fitem.uid}`))).val();
            await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${fitem.uid}`),
                {
                    group: cst?.group || "ostalo",
                    name: cst.name,
                    price: cst.price,
                    unit: cst?.unit || "/",
                    vat: cst.vat,
                    quantity: formatQuantity(formatQuantity(fitem?.quantity) + formatQuantity(cst.quantity))
                });
        }
    }
    for (const fitem of copyItems) {
        if (fitem?.isNew !== true) {
            changeItemQuantity(fitem.uid, fitem.quantity, CHANGE_ITEM_QUANTITY.sum, fitem.isItem).then(r => console.debug("r", r));
        }
    }
    return newFacture;
});

export const addFacturePayment = createAsyncThunk("addFacturePayment", async (data) => {
    await setDoc(doc(DB, "factures", `users`, AUTH.currentUser.uid, `${data.uid}`), data);
    return data;
});

const initialState = {
    factures: [],
    loading: false
};

const slice = createSlice({
    name: "factures",
    initialState,
    extraReducers: {
        [fetchAllFactures.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllFactures.fulfilled]: (state, {payload}) => {
            state.factures = payload.sort((a, b) => (moment(b.dateOfIssue).format("X") - (moment(a.dateOfIssue).format("X"))));
            state.loading = false;
        },
        [fetchAllFactures.rejected]: (state) => {
            state.loading = false;
        },
        [removeFacturePayment.pending]: (state) => {
            state.loading = true;
        },
        [removeFacturePayment.fulfilled]: (state, {meta}) => {
            let arr = [...current(state.factures)];
            let index = arr.findIndex(i => i.uid === meta.arg.uid);
            if (index !== -1) {
                arr[index] = meta.arg;
                state.factures = arr;
            }
            state.loading = false;
        },
        [removeFacturePayment.rejected]: (state) => {
            state.loading = false;
        },
        [addFactureRegistered.pending]: (state) => {
            state.loading = true;
        },
        [addFactureRegistered.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.factures)];
            const index = arr.findIndex(i => i.uid === payload.uid);
            arr[index] = payload;
            state.factures = arr;
            state.loading = false;
        },
        [addFactureRegistered.rejected]: (state) => {
            state.loading = false;
        },
        [addFacturePayment.pending]: (state) => {
            state.loading = true;
        },
        [addFacturePayment.fulfilled]: (state, {payload}) => {
            let arr = [...current(state.factures)];
            let index = arr.findIndex(i => i.uid === payload.uid);
            if (index !== -1) {
                arr[index] = payload;
                state.factures = arr;
            }
            state.loading = false;
        },
        [addFacturePayment.rejected]: (state) => {
            state.loading = false;
        },
        [deleteFacture.pending]: (state) => {
            state.loading = true;
        },
        [deleteFacture.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.factures)];
            const index = arr.findIndex(i => i.uid === payload.uid);
            arr.splice(index, 1);
            state.factures = arr;
            state.loading = false;
        },
        [deleteFacture.rejected]: (state) => {
            state.loading = false;
        },
        [updateFacture.pending]: (state) => {
            state.loading = true;
        },
        [updateFacture.fulfilled]: (state, {meta}) => {
            let arr = [...current(state.factures)];
            let index = arr.findIndex(i => i.uid === meta.arg.uid);
            if (index !== -1) {
                arr[index] = meta.arg;
                state.factures = arr;
            }
            state.loading = false;
        },
        [updateFacture.rejected]: (state) => {
            state.loading = false;
        }
    }
});

// Reducer
export default slice.reducer;
