import { useLoading } from "../../context/Loading";
import LoadingModal from "../../components/loading-modal";
import { Outlet } from "react-router-dom";

export default function OrdersLayout(){
  const { loading } = useLoading();
  return(
    <>
      {loading && <LoadingModal />}
      <Outlet />
    </>
  )
}