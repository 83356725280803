import { child, get, update } from "firebase/database";
import { AUTH, dbRef } from "../../auth/FirebaseContext";

export const getTermsOfUse = async (productId) => {
  return await (await get(child(dbRef, `/public/products/${productId}`))).val();
}

export const setTermsOfUseAccepted = async (productId) =>{
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings/acceptedLegal`), { [productId]: true });
}

export const isTermsOfUseAccepted = async (productId) => {
  return await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings/acceptedLegal/${productId}`))).val();
}