import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/auth/LoginPage';
import OperatorLogin from '../pages/auth/OperatorLoginPage';
import { useAuthContext } from './useAuthContext';
import {getSessionOperator} from "../helper/session";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({ children }) {

    const isAuthenticatedAsOperator = getSessionOperator();

    const { isAuthenticated, isInitialized } = useAuthContext();

    const { pathname } = useLocation();

    const [requestedLocation, setRequestedLocation] = useState(null);

    if (!isInitialized) {
        return <LoadingScreen />;
    }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Login />;
    }

    if (!isAuthenticatedAsOperator) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <OperatorLogin />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
}