import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {insertAllUserInvoicesOffline} from "../../store/offlineDb";

export const syncAllInvoices = createAsyncThunk('syncAllInvoices', async () => {
    return insertAllUserInvoicesOffline();
})

const initialState = {
    loading: false,
}

const slice = createSlice({
    name: 'synchronizations',
    initialState,
    reducers: {},
    extraReducers: {
        [syncAllInvoices.pending]: (state) => {
            state.loading = true;
        },
        [syncAllInvoices.fulfilled]: (state) => {
            state.loading = false;
        },
    }
});
export default slice.reducer;
