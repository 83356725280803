import { getSessionOperator, getSessionSelectedLocation } from "../../../helper/session";
import { Box, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { dispatch, useSelector } from "../../../redux/store";
import { fetchTIN } from "../../../redux/slices/settings";
import { getClientDetails } from "../../../redux/slices/customers";

export default function UserInfo() {
    const [user, setUser] = useState();
    const operator = getSessionOperator();
    const location = getSessionSelectedLocation();
    const [currentTime, setCurrentTime] = useState("");

    const { tin } = useSelector(state => state.settings);

    useEffect(() => {
        const interval = setInterval(() => {
            const time = moment().tz("Europe/Belgrade").format("DD-MM-YYYY hh:mm");
            setCurrentTime(time);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!tin) {
            dispatch(fetchTIN()).unwrap().then(response => {
                dispatch(getClientDetails(response)).unwrap().then(value => {
                    setUser(value);
                });
            });
        } else if (!user) {
            dispatch(getClientDetails(tin)).unwrap().then(value => {
                setUser(value);
            });
        }

    }, [tin]);

    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {user &&
                <Typography variant="subtitle1" color="text.disabled">
                    {user?.name} ({tin}) | {operator?.username} | {location?.name} | {currentTime}
                </Typography>
            }
        </Box>
    );
}
