import axios from 'axios';
// config
import {HOST_API_KEY} from '../config';
import {AUTH} from "../auth/FirebaseContext";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
    baseURL: '/', headers: {
        'ApiKey': '',
        'Accept': 'text/plain',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});


axiosInstance.interceptors.request.use(
    (config) => {
        const api_key = localStorage.getItem("api_key");
        if (api_key) {
            config.baseURL = HOST_API_KEY
            config.headers.ApiKey = `${api_key}`;
            config.headers.Authorization = `Bearer ${AUTH.supplier.stsTokenManager.accessToken}`
        }
        if (typeof config.data === 'string') {
            config.headers = {
                ...config.headers,
                'Authorization' :  `Bearer ${AUTH.supplier.stsTokenManager.accessToken}`,
                'Content-Type' : 'application/xml',
                'Accept' : 'application/xml'
            }

        }
        return config;
    },
    (error) => {
        // console.log("request error", error);
        return Promise.reject(error);
    }
);


axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

const BSS_URL = "https://europe-central2-esir-44ade.cloudfunctions.net/taxCoreData"

const customAxios = (token) => axios.create({
    baseURL: BSS_URL,
    headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`,
    },
});

export async function getClientInfo(tin) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).get(`/getCompanyDetails?TIN=${tin}`);
}

export default axiosInstance;


