import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
    name: PropTypes.string,
};

export default function RHFTextField({ type, rows, name, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            defaultValue = ""
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    type={type || 'string'}
                    size={'small'}
                    {...field}
                    rows={rows || 1}
                    fullWidth
                    value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                    error={!!error}
                    helperText={error?.message}
                    {...other}
                />
            )}
        />
    );
}
